import { css, SerializedStyles } from '@emotion/react'

// Example usage in a styled component
// const Box = styled.div`

//   background: lightblue;   <--- Mobile First Approach, any viewport smaller then   xsm: '360px', will be the background: lightblue etc..
//   padding: 16px;
//   color: white;

//   ${mediaEmotion.sm`
//     background: lightcoral;  <--- If this was the only breakpoint we added then any viewport bigger or equal to  sm: '640px' would be  background: lightcoral;
//   `}

//   ${mediaEmotion.md`
//     background: lightgreen;
//   `}

//   ${mediaEmotion.lg`
//     background: lightgoldenrodyellow;
//     color: black;
//   `}

//   ${mediaEmotion.xl`
//     background: lightpink;
//   `}
// `;

// Define the type for breakpoints
type Breakpoints = {
  [key: string]: string
}

type NumericBreakpoints = {
  [key: string]: number
}

// Define the type for the mediaEmotion function
type MediaEmotion = {
  [key in keyof Breakpoints]: (args: SerializedStyles) => SerializedStyles
}

export const breakpoints: Breakpoints = {
  xsm: '360px',
  sm: '640px',
  md: '960px',
  lg: '1024px',
  xl: '1440px',
  xxl: '1920px',
}

// The same as breakpoints, but the values are numeric
export const numericBreakpoints: { [key: string]: number } = Object.keys(breakpoints).reduce((acc, key) => {
  const numericValue = parseInt(breakpoints[key].replace('px', ''), 10)
  acc[key] = numericValue
  return acc
}, {} as NumericBreakpoints)

export const mediaEmotion: MediaEmotion = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (args: SerializedStyles): SerializedStyles => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(args)}
    }
  `
  return acc
}, {} as MediaEmotion)

export function removeConsecutiveSlashes(str: string) {
  return str.replace(/\/{2,}/g, '/')
}

export function removeSlashes(str: string) {
  return str.replace(/\//g, '')
}

export function removeLastSlash(str: string) {
  return str.replace(/\/$/, '')
}

export const capitalizeFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
